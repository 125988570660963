import '@/assets/style/index.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/style/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import '@/assets/style/animate.min.css';
// import 'lib-flexible/flexible';
import '@/components/SvgIcon'; // icon
import { Tabs, TabPane, Scrollbar, Dialog } from 'element-ui';
import * as zhLang from '@/lang/zh.js';
import * as enLang from '@/lang/en.js';

Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Scrollbar);
Vue.use(Dialog);

Vue.config.productionTip = false;
Vue.mixin({
  computed: {
    lang() {
      return this.$store.state.lang;
    },

    cdnUrl() {
      return this.$store.state.cdnUrl;
    },

    langDict() {
      return this.lang == 2 ? enLang : zhLang;
    }
  },
});
function buildApp() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

/* eslint-disable */
function mers_tm_preloader() {
  "use strict";
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )
    ? true
    : false;
  var preloader = $("#preloader");
  if (!isMobile) {
    setTimeout(function () {
      preloader.addClass("preloaded");
      buildApp();
    }, 300);
    setTimeout(function () {
      preloader.remove();
    }, 1000);
  } else {
    preloader.remove();
    buildApp();
  }
}

function mers_tm_my_load() {
  "use strict";
  var speed = 500;
  setTimeout(function () {
    mers_tm_preloader();
  }, speed);
}

$(document).ready(function () {
  new WOW().init();
  mers_tm_my_load();
});
