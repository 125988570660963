<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="container">
        <div class="logo">
          <a href="/">
            <img src="https://cdn.tbca.art/images/logo.png" class="logo-image" />
          </a>
        </div>


        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style="border: none"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav" @click="collapseNav" id="navbarNav">
            <li class="nav-item ">
              <a
                href="javascript:;"
                class="nav-link hvr-underline-from-center text-justify"
                @click="goPath('home')"
                :class="{ active: routeName == 'home' }"
              >
                {{ menu.home }}
              </a>
            </li>

            <li class="nav-item">
              <a
                href="javascript:;"
                @click="goPath('exhibition')"
                :class="{ active: routeName == 'exhibition' }"
                class="nav-link hvr-underline-from-center "
              >
                {{ menu.exhibition }}
              </a>
            </li>

            <li class="nav-item">
              <a
                href="javascript:;"
                class="nav-link hvr-underline-from-center "
                :class="{ active: ['introduce', 'introduceMore'].includes(routeName) }"
                @click="goPath('introduce')"
              >
                {{ menu.introduce }}
              </a>
            </li>

            <li class="nav-item " v-if="activityList.length>0">
              <a
                href="javascript:;"
                @click="goPath('activity')"
                :class="{ active: ['activity', 'activityDetail'].includes(routeName) }"
                class="nav-link hvr-underline-from-center "
              >
                {{ menu.event }}
              </a>
            </li>

            <li class="nav-item" v-if="newsList.length>0">
              <a
                href="javascript:;"
                @click="goPath('news')"
                :class="{ active: ['news', 'newsDetail'].includes(routeName) }"
                class="nav-link hvr-underline-from-center "
              >
                {{ menu.news }}
              </a>
            </li>



            <li class="nav-item">
              <a
                href="javascript:;"
                @click="goPath('guide')"
                :class="{ active: routeName == 'guide' }"
                class="nav-link hvr-underline-from-center "
              >
                {{ menu.guide }}
              </a>
            </li>

            <li class="nav-item">
              <a
                href="javascript:;"
                @click="goPath('organization')"
                :class="{ active: routeName == 'organization' }"
                class="nav-link hvr-underline-from-center "
              >
                {{ menu.organ }}
              </a>
            </li>


          </ul>
        </div>
        <template>
          <img
            class="icon-lang"
            src="https://cdn.tbca.art/images/icon_Ch.svg"
            @click="toggleLang"
            v-if="lang == 2"
          />
          <img class="icon-lang" src="https://cdn.tbca.art/images/icon_En.svg" @click="toggleLang" v-else />
        </template>

      </div>
    </nav>
    <nav class="navbar nav-empty"></nav>
    <router-view :key="routeName" />
  </div>
</template>

<script>
import { menu as zhMenu } from "@/lang/zh.js";
import { menu as enMenu } from "@/lang/en.js";
import axios from 'axios';

export default {
  data() {
    return {
      isMobile: false,
      newsList: [],
      activityList: [],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    menu() {
      return this.lang == 2 ? enMenu : zhMenu;
    },
  },
  created() {
    this.judgeMobile();
    this.getNewsList();
    this.getActivityList();
    $(window).resize(this.judgeMobile);
  },
  mounted() {
    $(".navbar-toggler").click(function(res){
      const $div = $("#navbarNav");
      if ($div.hasClass("show"))
      {//隱藏選單
        $div.removeClass("show");
      } else {
        //否則顯示選單
        $div.addClass("show");
      }
    });
  },
  methods: {
    getActivityList() {
        axios.get('https://tbcaapi.shumixgsh.cn/api/website/activity', {
            headers: {
                lang: this.lang,
            },
            params: {
                page: 1,
            }
        }).then((res) => {
            let data = _.result(res, 'data.data', {});
            let listData = data.data || [];
            this.activityList = listData;
        });
    },
    getNewsList() {
        axios.get('https://tbcaapi.shumixgsh.cn/api/website/news', {
            headers: {
                lang: this.lang,
            },
            params: {
                lang: this.lang,
                page: 1,
            }
        }).then((res) => {
            let data = _.result(res, 'data.data', {});
            let listData = data.data || [];
            this.newsList = listData;
        });
    },
    judgeMobile() {
      let width = $(document.body).width();
      this.isMobile = width < 768;
    },
    goPath(name) {
      $(window).scrollTop(0);
      this.$router.push({ name });
    },
    collapseNav() {
      let clientW = $(document.body).width();
      clientW < 768 && $(".navbar-toggler").click();
    },
    toggleLang() {
      let lang = localStorage.getItem("lang");
      if (lang == 2) {
        localStorage.setItem("lang", "1");
      } else {
        localStorage.setItem("lang", "2");
      }
      window.location.href = window.location.origin;
    },
  },
};
</script>

<style lang="scss" scoped>
@function px-to-vw($target-px, $base-font-size: 19.2px) {
  @return ($target-px / $base-font-size) * 1vw;
}

#app {
  overflow-x: hidden;
}

/*================================================
Navbar Area CSS
=================================================*/
.nav-empty {
  visibility: hidden;
  height: 54px;
}
.navbar {
  background: #fff !important;
  padding-top: 8px;
  padding-bottom: 8px;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: var(--whiteColor) !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}

.navbar-light {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  .logo {
    h3 {
      font-size: 35px;
      margin-bottom: 0;
    }
  }

  .logo-image {
    width: 36px;
    height: 36px;
  }

  .navbar-nav {
    margin: auto;

    .nav-item {
      position: relative;
      padding: 0;

      a {
        font: {
          size: var(--fontSize);
        }

        color: $app_font-color-light;
        text-transform: capitalize;
        position: relative;

        padding: {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        margin: {
          left: 20px;
          right: 20px;
        }

        &:hover,
        &:focus,
        &.active {
          color: $app_font-color;
          position: relative;
          transition: var(--transition);
          font-weight: 600;
        }

        i {
          font-size: 16px;
          position: relative;
          top: 2px;
          display: inline-block;
          margin-left: -4px;
          margin-right: -4px;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:hover,
      &.active {
        a {
          color: $app_font-color;
          transition: var(--transition);
        }
      }
    }
  }

  .others-option {
    .option-item {
      .social-icon {
        padding-left: 0;
        margin-bottom: 0;

        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }

          i {
            display: inline-block;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #dddddd;
            border-radius: 50px;
            font-size: 20px;
            font-weight: 600;
            transition: var(--transition);
            color: var(--blackColor);

            &:hover {
              background-color: var(--mainColor);
              border: 1px solid var(--mainColor);
              color: var(--whiteColor);
            }
          }
        }
      }
    }
  }
}

.icon-lang {
  width: px-to-vw(24px);
  cursor: pointer;
}

@keyframes animation-li1 {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
ul.navbar-nav > li {
  animation-duration: 1s;
}
@media (max-width: 768px) {
  .nav-item {
    text-align: center;
    font-size: px-to-vw(14px, 3.75px) !important;
    line-height: px-to-vw(18px, 3.75px) !important;
    padding: px-to-vw(18px, 3.75px) px-to-vw(130px, 3.75px) !important;
    a {
      margin: 0px !important;
    }
  }
  .icon-lang {
    width: 30px;
    position: absolute;
    right: 90px;
    top: 13px;
  }
  .show  > li:nth-child(1){
    animation-name: animation-li1;
  } .show  > li:nth-child(2){
    animation-name: animation-li1;
    animation-duration: 1.1s;
  }.show  > li:nth-child(3){
    animation-name: animation-li1;
           animation-duration: 1.2s;
  }.show  > li:nth-child(4){
    animation-name: animation-li1;
              animation-duration: 1.3s;
  }.show  > li:nth-child(5){
    animation-name: animation-li1;
                 animation-duration: 1.4s;
  }.show  > li:nth-child(6){
    animation-name: animation-li1;
                    animation-duration: 1.5s;
  }.show  > li:nth-child(6){
    animation-name: animation-li1;
                       animation-duration: 1.6s;
  }.show  > li:nth-child(7){
    animation-name: animation-li1;
                          animation-duration: 1.7s;
  }
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus
{
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
