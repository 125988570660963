import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let lang = localStorage.getItem('lang');

export default new Vuex.Store({
  state: {
    cdnUrl: 'https://cdn.tbca.art/',
    lang
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
