import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'root',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home'),
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import(/* webpackChunkName: "about" */ '@/views/exhibition'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news'),
  },
  {
    path: '/news-detail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news-detail'),
  },
  {
    path: '/activity-detail',
    name: 'activityDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/activity-detail'),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "about" */ '@/views/activity'),
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import(/* webpackChunkName: "about" */ '@/views/introduce'),
  },
  {
    path: '/introduce-more',
    name: 'introduceMore',
    component: () => import(/* webpackChunkName: "about" */ '@/views/introduce-more'),
  },
  {
    path: '/introduce-detail',
    name: 'introduceDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/introduce-detail'),
  },
  {
    path: '/organization',
    name: 'organization',
    component: () => import(/* webpackChunkName: "about" */ '@/views/organization'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import(/* webpackChunkName: "about" */ '@/views/guide'),
  },
  {
    path: '/location',
    name: 'location',
    component: () => import(/* webpackChunkName: "about" */ '@/views/location'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
