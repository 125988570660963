module.exports = {
  menu: {
    home: '首　　页',
    event: '活动预告',
    news: '新闻中心',
    exhibition: '展览介绍',
    guide: '参观指南',
    introduce: '展品介绍',
    organ: '组织架构',
  },
  date: '日期',
  propaganda: '宣传',
  detail: '查看详情',
  siteLabel: '展览地址',
  site: '上海市浦东新区世博大道<br/>世博园意大利中心',
  siteNotBr: '上海世博文化公园-原意大利馆',
  openTime: '开放时间',
};
