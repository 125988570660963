module.exports = {
  menu: {
    home: 'Home',
    event: 'Events',
    news: 'News',
    exhibition: 'Introduction',
    guide: 'Guide',
    introduce: 'Expo',
    organ: 'Structure',
  },
  date: 'Date',
  propaganda: 'Propaganda',
  detail: 'Detail',
  siteLabel: 'Exhibitionaddress',
  site: 'ExpoAvenue,PudongNewArea,Shanghai<br/> ExpoParkItalyCenter',
  siteNotBr: 'Shanghai EXPO Culture Park-ltaly Pavilion',
  openTime: 'OpeningTimes',
};





